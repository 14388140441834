<template>
  <div>
    <b-container fluid>
        <b-row>
            <b-col>
                <div class="title"><h1>{{data.whyMustTitle}}</h1></div>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12" class="bannerContent whyMustGrey">
                <b-row>
                    <b-col sm="6" class="bannerContentProduct">
                        <b-row>
                            <b-col sm="12"><h1>{{data.whyMustProdukTitle}}</h1></b-col>
                            <b-col sm="12"><p>{{data.whyMustProdukDesc}}</p></b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="6" class="bannerContentProduct" align="center">
                        <img src="../../assets/images/icon/why-must1.jpeg" width="100%" alt="Produk Terpecaya" desc="Produk Terpecaya">
                     </b-col>
                </b-row>
            </b-col>
            <b-col sm="12" class="bannerContent whyMustWhite">
            <div class="for_desktop">
                <b-row>
                    <b-col sm="6" class="bannerContentProduct">
                        <img src="../../assets/images/icon/why-must2.jpeg" width="100%" alt="Promosi Produk" desc="Promosi Produk">
                    </b-col>
                    <b-col sm="6" class="bannerContentProduct">
                        <b-row>
                            <b-col sm="12"><h1>{{data.whyMustPromosiTitle}}</h1></b-col>
                            <b-col sm="12"><p>{{data.whyMustPromosiDesc}}</p></b-col>
                        </b-row>
                    </b-col>
                </b-row>  
            </div>  
            <div class="for_tablet">
                <b-row>
                    <b-col sm="6" class="bannerContentProduct">
                        <img src="../../assets/images/icon/why-must2.jpeg" width="100%" alt="Promosi Produk" desc="Promosi Produk">
                    </b-col>
                    <b-col sm="6" class="bannerContentProduct">
                        <b-row>
                            <b-col sm="12"><h1>{{data.whyMustPromosiTitle}}</h1></b-col>
                            <b-col sm="12"><p>{{data.whyMustPromosiDesc}}</p></b-col>
                        </b-row>
                    </b-col>
                </b-row>  
            </div> 
            <div class="for_mobile">
                <b-row>   
                    <b-col sm="6" class="bannerContentProduct">
                        <b-row>
                            <b-col sm="12"><h1>{{data.whyMustPromosiTitle}}</h1></b-col>
                            <b-col sm="12"><p>{{data.whyMustPromosiDesc}}</p></b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="6" class="bannerContentProduct">
                        <img src="../../assets/images/icon/why-must2.jpeg" width="100%" alt="Promosi Produk" desc="Promosi Produk">
                    </b-col>
                </b-row>
            </div>
            </b-col>
            <b-col sm="12" class="bannerContent whyMustGrey">
                <b-row>
                    <b-col sm="6" class="bannerContentProduct">
                        <b-row>
                            <b-col sm="12"><h1>{{data.whyMustPengirimanTitle}}</h1></b-col>
                            <b-col sm="12"><p>{{data.whyMustPengirimanDesc}}</p></b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="6" class="bannerContentProduct">
                        <img src="../../assets/images/icon/why-must3.jpeg" width="100%" alt="Pengiriman Mudah" desc="Pengiriman Mudah">
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
  export default {
      data() {
        return {
            bahasa:'id',
            data: {
                whyMustTitle:null,
                whyMustProdukTitle:null,
                whyMustProdukDesc:null,
                whyMustPromosiTitle:null,
                whyMustPromosiDesc:null,
                whyMustPengirimanTitle:null,
                whyMustPengirimanDesc:null
            }
        }
      },
      mounted() {
          this.bahasa = localStorage.getItem('bahasa')
          axios.get(`../../lang_${this.bahasa}.json`).then(response => {
            //   console.log(response)
            // console.log(response.data.data[5].whyMust[0].whyMustProdukTitle)
            this.data.whyMustTitle = response.data.data[5].whyMust[0].whyMustTitle

            this.data.whyMustProdukTitle = response.data.data[5].whyMust[0].whyMustProdukTitle;
            this.data.whyMustProdukDesc = response.data.data[5].whyMust[0].whyMustProdukDesc;

            this.data.whyMustPromosiTitle = response.data.data[5].whyMust[0].whyMustPromosiTitle;
            this.data.whyMustPromosiDesc = response.data.data[5].whyMust[0].whyMustPromosiDesc;
            
            this.data.whyMustPengirimanTitle = response.data.data[5].whyMust[0].whyMustPengirimanTitle;
            this.data.whyMustPengirimanDesc = response.data.data[5].whyMust[0].whyMustPengirimanDesc;
          })
      }
  }
</script>

